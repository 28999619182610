
<template>
  <div>
    <v-hover v-slot:default="{ hover }">
      <v-list-item
        :class="{ 'grey lighten-4': hover }"
        @click="expanded = !expanded"
      >
        <v-list-item-content class="pa-0 ma-0">
          <v-list-item-title class>
            <span class="font-weight-regular">{{ node.resource_name }}</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="py-0 my-0">
          <v-btn
            v-if="!expanded"
            @click.self="expanded = true"
            fab
            class="ma-0 pa-0"
            x-small
            text
            color="grey"
          >
            <v-icon small>fas fa-chevron-down</v-icon>
          </v-btn>
          <v-btn
            v-else
            @click.self="expanded = false"
            fab
            class="ma-0 pa-0"
            x-small
            text
            color="grey"
          >
            <v-icon small>fas fa-chevron-up</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
    <div v-if="expanded">
      <div v-if="node.permissions.length > 0" class="ml-5">
        <v-layout row wrap align-baseline mb-1 pl-2>
          <p class="subtitle-1 ma-0 mt-2 font-weight-medium primary--text mb-0">
            Permissions
          </p>
        </v-layout>
        <span v-for="perm in node.permissions" :key="perm.id">
          <p class="font-weight-regular ml-4 mb-1"> &#8212; {{
            perm.display_name ? perm.display_name : perm
          }}</p>
        </span>
        <br />
      </div>
      <div v-else>
        <p class="subtitle-2 ml-4 font-italic">Resource has no permissions</p>
      </div>
      <!-- <div class="ml-7" v-if="node.children.length > 0">
        <node
          v-for="child in node.children"
          :node="child"
          :key="child.id"
        ></node>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'node',
  props: {
    node: {}
  },
  data() {
    return {
      expanded: false
      // indeterminate: false
    }
  },
  methods: {
  },
  computed: {
  },
  beforeDestroy() {
    this.expanded = false
  }
}
</script>

<style lang="scss" scoped>
</style>
