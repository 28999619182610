<template>
  <div>
    <v-dialog
      id="userSettingsDialog"
      no-click-animation
      scrollable
      :value="status"
      persistent
      fullscreen
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="mx-0 px-3">
          <v-layout mx-1 row wrap align-center align-content-center>
            <v-icon v-if="!profile" size="22" class="mr-4">fas fa-cog</v-icon>
            <v-icon v-else size="22" class="mr-4">fas fa-user</v-icon>
            <span v-if="!profile">USER SETTINGS:</span>
            <span v-else>USER PROFILE</span>

            <span
              class="ml-2 text-capitalize grey--text text--darken-1 font-weight-bold"
              >{{ user.first_name }} {{ user.last_name }}</span
            >
            <v-spacer></v-spacer>
            <v-btn @click="closeModal" fab small icon>
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <!-- GOOD STUFF START -->
          <v-tabs>
            <v-tab v-if="!profile">
              <v-icon small left>fas fa-user-cog</v-icon>Roles
            </v-tab>
            <v-tab> <v-icon left small>fas fa-user</v-icon>Profile </v-tab>
            <v-tab v-if="vg_isLocationsEnabled">
              <v-icon left small>place</v-icon>Locations &amp; Groups
            </v-tab>
            <v-tab> <v-icon left small>fas fa-lock</v-icon>Password </v-tab>
            <v-tab> <v-icon left small>fas fa-cog</v-icon>Settings </v-tab>
            <!-- User Roles Item START -->
            <v-tab-item v-if="!profile">
              <v-card flat>
                <v-col cols="12" md="4" class="pb-0"> </v-col>
                <v-card-title primary-title>User Roles</v-card-title>
                <v-card-subtitle
                  >Choose application roles for your user</v-card-subtitle
                >
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                      sm="4"
                      v-for="app in userRoles"
                      :key="app.display_name"
                    >
                      <v-card>
                        <v-card-title primary-title>{{
                          app.display_name
                        }}</v-card-title>
                        <v-card-subtitle>{{ app.description }}</v-card-subtitle>
                        <v-card-text>
                          <v-list>
                            <v-hover
                              v-for="role in app.roles"
                              :key="role.id"
                              v-slot:default="{ hover }"
                            >
                              <v-list-item
                                dense
                                style="max-height: 35px !important"
                                class="py-0 my-0"
                              >
                                <template>
                                  <v-list-item-action class="py-0 my-0">
                                    <v-checkbox
                                      class="py-0 my-0"
                                      hide-details
                                      v-model="role.enabled"
                                      @change="handleRoleChange(role, $event)"
                                    ></v-checkbox>
                                  </v-list-item-action>

                                  <v-list-item-content class="py-0 my-0">
                                    <v-list-item-title>{{
                                      role.display_name
                                    }}</v-list-item-title>
                                    <!-- <v-list-item-subtitle
                                    >Notify me about updates to apps or games
                                    that I downloaded</v-list-item-subtitle
                                  > -->
                                  </v-list-item-content>
                                  <v-fade-transition>
                                    <v-list-item-action
                                      v-if="hover"
                                      @click="openRolesMenu($event, role)"
                                      class="py-0 my-0"
                                    >
                                      <v-icon size="20">info</v-icon>
                                    </v-list-item-action>
                                  </v-fade-transition>
                                </template>
                              </v-list-item>
                            </v-hover>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <!-- <span v-for="role in filteredRoles" :key="role.id">
                    <v-checkbox
                      :value="isUserRole(role)"
                      @change="handleRoleChange(role, $event)"
                      :label="role.display_name"
                      hide-details
                      class="pa-0 ma-0 my-1"
                    ></v-checkbox>
                  </span>-->
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- User Roles Item END -->
            <!-- User PROFILE Item START -->
            <v-tab-item>
              <v-card flat>
                <v-card-title primary-title>User Profile</v-card-title>
                <v-card-subtitle
                  >Change basic information about the user</v-card-subtitle
                >
                <v-card-text>
                  <v-form ref="user-form">
                    <v-text-field
                      v-model="localUser.first_name"
                      name="FirstName"
                      :rules="nameRules"
                      label="First Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="localUser.last_name"
                      name="FirstName"
                      :rules="nameRules"
                      label="Last Name"
                    ></v-text-field>
                    <v-text-field
                      v-model="localUser.email"
                      prepend-inner-icon="email"
                      disabled
                      :rules="emailRules"
                      name="FirstName"
                      label="Email"
                    ></v-text-field>
                    <v-text-field
                      v-model="localUser.phone"
                      prepend-inner-icon="phone"
                      name="FirstName"
                      label="Phone Number"
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="saveUserProfile"
                      elevation="0"
                      color="primary"
                      class="text-capitalize"
                      >Save Changes</v-btn
                    >
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item>
              <v-card flat>
                <v-card-title primary-title
                  >User Location &amp; Location Groups</v-card-title
                >
                <v-card-subtitle
                  >View groups and locations assigned to the
                  user</v-card-subtitle
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6" sm="6">
                      <v-card>
                        <v-card-title primary-title>Locations</v-card-title>
                        <v-card-text>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Name</th>
                                  <th class="text-left">Address</th>
                                  <th class="text-left">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item in locations" :key="item.id">
                                  <td>{{ item.display_name }}</td>
                                  <td>
                                    {{
                                      item.address
                                        ? item.address.formatted_address
                                        : ""
                                    }}
                                  </td>
                                  <td>
                                    <v-btn
                                      color="primary"
                                      x-small
                                      elevation="0"
                                      fab
                                      @click="
                                        $router.push({
                                          path:
                                            '/locations-and-groups/locations',
                                          query: {
                                            location_id: item.id,
                                            first_name: user.first_name,
                                            last_name: user.last_name,
                                          },
                                        })
                                      "
                                      ><v-icon>chevron_right</v-icon></v-btn
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="6" sm="6">
                      <v-card>
                        <v-card-title primary-title
                          >Location Groups</v-card-title
                        >
                        <v-card-text>
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">Name</th>
                                  <th class="text-left">Description</th>
                                  <th class="text-left">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="item in locationGroups"
                                  :key="item.id"
                                >
                                  <td>{{ item.name }}</td>
                                  <td>
                                    {{ item.description }}
                                  </td>
                                  <td>
                                    <v-btn
                                      color="primary"
                                      x-small
                                      elevation="0"
                                      fab
                                      @click="
                                        $router.push({
                                          path: '/locations-and-groups/groups',
                                          query: {
                                            location_group_id: item.id,
                                            first_name: user.first_name,
                                            last_name: user.last_name,
                                          },
                                        })
                                      "
                                      ><v-icon>chevron_right</v-icon></v-btn
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <!-- User PROFILE Item END -->

            <!-- User PASSWORD Item START -->
            <v-tab-item>
              <v-card flat>
                <v-card-title primary-title>User Security</v-card-title>
                <v-card-subtitle
                  >Configure user credentials such as passwords, pins
                  etc</v-card-subtitle
                >
                <v-card-text>
                  <v-form @submit.prevent ref="password-form">
                    <v-text-field
                      v-if="amMyself && !_isCorporateUser"
                      v-model="password.current.value"
                      :rules="passwordRules"
                      prepend-icon="lock"
                      name="password"
                      label="Current Password"
                      id="CurrentPassword"
                      validate-on-blur
                      :append-icon="
                        password.current.show ? 'visibility_off' : 'visibility'
                      "
                      :type="password.current.show ? 'text' : 'password'"
                      @click:append="
                        password.current.show = !password.current.show
                      "
                    ></v-text-field>
                    <v-text-field
                      v-model="password.new.value"
                      :rules="passwordRules"
                      prepend-icon="lock"
                      name="password"
                      label="New Password"
                      id="password"
                      autocomplete="on"
                      validate-on-blur
                      :append-icon="
                        password.new.show ? 'visibility_off' : 'visibility'
                      "
                      :type="password.new.show ? 'text' : 'password'"
                      @click:append="password.new.show = !password.new.show"
                    ></v-text-field>
                    <v-text-field
                      v-model="password.confirm.value"
                      :rules="passwordRules"
                      prepend-icon="lock"
                      name="password"
                      label="Confirm Password"
                      id="confirmPassword"
                      validate-on-blur
                      :append-icon="
                        password.confirm.show ? 'visibility_off' : 'visibility'
                      "
                      :type="password.confirm.show ? 'text' : 'password'"
                      @click:append="
                        password.confirm.show = !password.confirm.show
                      "
                    ></v-text-field>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-layout row wrap>
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="handlePasswordChange"
                      elevation="0"
                      color="primary"
                      class="text-capitalize"
                      >Save Changes</v-btn
                    >
                  </v-layout>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <!-- User PASSWORD Item END -->
            <v-tab-item>
              <v-card
                v-for="usetSetting in userSettings"
                :key="usetSetting.display_name"
                flat
              >
                <v-card-title primary-title
                  >{{ usetSetting.display_name }}s</v-card-title
                >
                <v-card-subtitle>{{ usetSetting.description }}</v-card-subtitle>
                <v-card-text>
                  <v-row
                    class="ma-0 pa-0 align-center"
                    v-for="item in usetSetting.setting_options"
                    :key="item.id"
                  >
                    <v-col class="ma-0 pa-0 my-2" cols="12" sm="6">
                      <v-card>
                        <v-card-text>
                          <v-layout>
                            <div>
                              <p class="pa-0 ma-0 subtitle-1 font-weight-bold">
                                {{ item.display_name }}
                              </p>
                              <p
                                class="pa-0 ma-0 subtitle-2 font-weight-regular"
                              >
                                {{ item.description }}
                              </p>
                            </div>
                            <v-spacer></v-spacer>
                            <span>
                              <span v-if="item.value_type === 'boolean'">
                                <v-switch
                                  class="pa-0 ma-0"
                                  hide-details
                                  v-model="item.current_value"
                                  @change="updateUserSettings(item)"
                                ></v-switch>
                              </span>
                              <span v-if="item.value_type === 'string'">
                                <v-text-field
                                  v-model="item.current_value"
                                ></v-text-field>
                              </span>
                              <span v-if="item.value_type === 'number'">
                                <v-text-field
                                  v-model="item.current_value"
                                ></v-text-field>
                              </span>
                            </span>
                          </v-layout>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
          <!-- GOOD STUFF END -->
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- ROLES DISPLAY MENU -->
    <v-dialog
      v-model="showRolesMenu"
      scrollable
      max-width="550px"
      transition="dialog-transition"
    >
      <v-card v-if="permission.length > 0">
        <v-card-title primary-title> Role Permissions </v-card-title>
        <v-card-text>
          <span v-for="(node, index) in permission" :key="index + '123'">
            <tree-view-2 :node="node"></tree-view-2>
            <v-divider v-if="!index < permission.length"></v-divider>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showRolesMenu = false" text
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title primary-title> Permissions Not Found </v-card-title>
        <v-card-subtitle primary-title>
          No permissions found for the selected user. Please assign a role and
          try again.
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showRolesMenu = false" text
            >close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TreeView2 from '@/components/custom-components/TreeView2'

import { RolesAPI } from '@/clients/permissions/roles'
import { UsersAPI } from '@/clients/permissions/users'
import { mapGetters, mapMutations } from 'vuex'
import { AuthAPI } from '../../clients/auth/auth.api'
import { LocationsAPI } from '@/clients/locations/locationsapi'
export default {
  components: {
    TreeView2
  },
  async created() {
    await this.resetLocalUser()
    await this.getUserRoles()
    await this.getUserSettings()
    if (this.vg_isLocationsEnabled) {
      this.getLocationsForUser()
      this.getLocationGroupsForUser()
    }
  },
  props: {
    status: {
      default: false
    },
    user: {
      required: true
    },
    profile: {
      default: false
    }
  },
  data: function () {
    return {
      permission: [],
      showRolesMenu: false,
      x: 0,
      y: 0,
      password: {
        current: {
          value: '',
          show: false
        },
        new: {
          value: '',
          show: false
        },
        confirm: {
          value: '',
          show: false
        }
      },
      nameRules: [
        v => !!v || 'Name is required'
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /^.+@.+\..+$/.test(v) || 'E-mail must be valid'
      ],
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be more than 8 characters'
      ],
      passwordCheckRules: [v => !!v || 'Password is required'],
      localUser: null,
      roles: [],
      userRoles: [],
      search: '',
      userSettings: [],
      locations: [],
      locationGroups: []
    }
  },
  methods: {
    ...mapMutations({ setBasicUserInfo: 'auth/SET_BASIC_USER_INFO' }),
    closeModal() {
      this.$emit('close-modal')
    },
    resetLocalUser() {
      this.localUser = JSON.parse(JSON.stringify(this.user))
    },
    async getUserRoles() {
      this.spinner(true)
      let response
      try {
        response = await UsersAPI.getUserRoleWithApps(this.localUser.id)
      } catch (error) {
        this.spinner(false)
        this.userRoles = []
        return console.log(error)
      }
      this.userRoles = response.data.payload
      this.spinner(false)
    },
    async getUserSettings() {
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.getUserSettings(this.localUser.id)
      } catch (error) {
        return console.log(error)
      }
      this.userSettings = response.data.payload
      this.spinner(false)
    },
    handleRoleChange(role, checkboxValue) {
      if (checkboxValue) {
        this.addUserRole(role)
      } else {
        this.removeUserRole(role)
      }
    },
    async addUserRole(role) {
      this.spinner(true)
      let data = {
        role_ids: [role.id]
      }
      try {
        await UsersAPI.addUserRoles(this.localUser.id, data)
        await this.getUserRoles()
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.spinner(false)
    },
    async removeUserRole(role) {
      let data = {
        role_ids: [role.id]
      }
      this.spinner(true)
      try {
        await UsersAPI.removeUserRoles(this.localUser.id, data)
        await this.getUserRoles()
      } catch (error) {
        return console.log(error)
      }
      this.spinner(false)
    },
    isUserRole(role) {
      return this.userRoles.findIndex(i => i.id === role.id) > -1
    },
    async saveUserProfile() {
      if (!this.$refs['user-form'].validate()) {
        return false
      }
      this.spinner(true)
      let response
      try {
        response = await AuthAPI.updateUserById(this.localUser.id, this.localUser)
      } catch (error) {
        return console.log(error)
      }
      this.user = this.localUser
      this.spinner(false)
      this.swal({
        title: 'Success',
        text: 'Successfully updated user profile',
        type: 'success',
        timer: 1500
      })
      if (this.profile) {
        this.setBasicUserInfo(this.localUser)
      }
    },
    async changeMyPassword() {
      let data = {
        current_password: this.password.current.value,
        new_password: this.password.new.value,
        confirm_new_password: this.password.confirm.value
      }
      this.spinner(true)
      try {
        await AuthAPI.changePassword(data)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.password.new.value = ''
      this.password.confirm.value = ''
      this.password.current.value = ''
      this.spinner(false)
      this.swal({
        title: 'Success',
        text: 'Successfully updated user password',
        type: 'success',
        timer: 1500
      })
    },
    async changePasswordById() {
      let data = {
        new_password: this.password.new.value,
        confirm_new_password: this.password.confirm.value
      }
      this.spinner(true)
      try {
        await AuthAPI.changePasswordByUserId(this.user.id, data)
      } catch (error) {
        this.spinner(false)
        return console.log(error)
      }
      this.spinner(false)
      this.password.new.value = ''
      this.password.confirm.value = ''
      this.swal({
        title: 'Success',
        text: 'Successfully updated user Password',
        type: 'success',
        timer: 1500
      })
    },
    handlePasswordChange() {
      if (!this.$refs['password-form'].validate()) {
        return false
      }
      if (this._isCorporateUser) {
        this.changePasswordById()
      } else if (this.amMyself) {
        this.changeMyPassword()
      }
    },
    async updateUserSettings(setting) {
      let data = {
        value: setting.current_value,
        settings_option_id: setting.id
      }
      try {
        await AuthAPI.updateUserSettings(this.user.id, data)
      } catch (error) {
        setting.current_value = !setting.current_value
      }
    },
    async getLocationsForUser() {
      let res
      try {
        res = await LocationsAPI.getAllLocationsForUser(this.vg_activeBusiness.id, this.user.id)
      } catch (error) {
        console.log(error)
      }
      this.locations = res.data.payload
    },
    async getLocationGroupsForUser() {
      let res
      try {
        res = await LocationsAPI.getAllLocationsForUser(this.vg_activeBusiness.id, this.user.id, true)
      } catch (error) {
        console.log(error)
      }
      this.locationGroups = res.data.payload
    },
    async openRolesMenu(e, data) {
      this.spinner(true)
      e.preventDefault()
      this.showRolesMenu = false
      this.permission = data.permissions
      this.x = e.clientX
      this.y = e.clientY
      this.$nextTick(() => {
        this.showRolesMenu = true
      })
      console.log(e, data)
      this.spinner(false)
    },
    async getPermissionForRole(role) {
      let response
      try {
        response = await RolesAPI.getPermissionsForARole(role.id)
      } catch (error) {
        this.spinner(false)
        console.log(error)
        return
      }
      this.spinner(false)
      // this.editRoleDialog.selected = role
      this.permission = response.data.payload
      // this.editRoleDialog.status = true
    }
  },
  computed: {
    ...mapGetters({
      vg_user: 'auth/user',
      vg_activeBusiness: 'auth/activeBusiness',
      vg_isLocationsEnabled: 'applications/isLocationsEnabled'
    }),
    amMyself() {
      return this.user.id === this.vg_user.id
    },
    amCorporate() {
      return this.user.is_corporate
    }
  },
  watch: {
    showRolesMenu: {
      handler: function (x) {
        if (x) {
          this.$root.$emit('scroll-toggle', x)
        } else {
          this.$root.$emit('scroll-toggle', x)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
